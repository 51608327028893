import React, { Component } from "react";
import Api from "../../../libraries/api";
import ParkingProductCheck from "./ParkingProductCheck";
import { Form } from "react-bootstrap";

export default class ParkingProductList extends Component {
  constructor(props) {
    super(props);
    this.releaseManagementGetUrl = "/release-management-value";

    this.state = {
        loading: true,
        products: [{
            id: 0,      // to be overwritten 
            status: 1,  // to be overwritten
            product: "Release Management",
            name: "ReleaseManagement"
            }],
        reasons:[] // array of strings for disabled statuses
    };
    // set loading flag
    this.loading = true;
    // set promise(s)
    const promiseReleaseManagement = Api.get(this.releaseManagementGetUrl, true, "parking");
    // only one promise for now, add more here
    const promises = [promiseReleaseManagement];
    // wait on all promises
    Promise.all(promises)
    .then((values) => {
        // console.log(values)
        const oldProducts = this.state.products;
        const newProducts = [];
        for (let i=0; i < values.length; i++) { 
            newProducts.push({
                id: values[i].id,
                status: values[i].toggleValue,
                product: oldProducts[i].product
            });
        }
        // set new state with data
        this.setState({products : newProducts});
    }).catch((error) => {
        console.error(error)
    }).finally(() => {
        // disable loading flag
        this.setState({ loading : false });
    })
    // bind functions
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleReasonChange(event) {
    // console.log('id = ',event.target.id, ",value = ", event.target.value);
    //const id = event.target.id;
    //const curValue = event.target.value;
  }

  handleChange(product, status) {
    // console.log(`product=${product}, status=${status}`);
    const updatedProducts = [];
    for (const p of this.state.products) {
      if (p.product === product) {
        updatedProducts.push({
          ...p,
          status: status ? 1 : 0
        })
      } else {
        updatedProducts.push({
          ...p
        })
      }
    }
    this.setState({ products: updatedProducts });
  }

  render() {
    const { loading } = this.state;
    return loading ? 
        (<div>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> ) :
        (<div>
            <div class="row">
                <div class="col-md-5 font-weight-bold">Product</div>
                <div class="col-md-2 font-weight-bold">Status</div>
                <div class="col-md-5 font-weight-bold">Reason</div>
            </div>            
            {this.state.products.map((item,_) => {
            return (
                <div key={item.id} class="row">
                    <div class="col-md-5">{item.product}</div>
                    <div class="col-md-2">
                        <ParkingProductCheck 
                            itemId={item.id} 
                            isChecked={item.status} 
                            product={item.product} 
                            handleChange={this.handleChange} 
                        />
                    </div>
                    {item.status === 0 &&
                        <div class="col-md-5">
                            <Form.Control 
                                type="text" 
                                size="sm" 
                                onChange={this.handleReasonChange} 
                                id={item.product} 
                                name="ParkingProductReason" 
                            />
                        </div> 
                    }
                </div>
            ); })}
      </div>) 
  }
}