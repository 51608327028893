import React, { Component } from "react";
import ProductConfigList from "./ProductConfigList";
import ParkingProductList from "./ParkingProductList";
import Api from "../../../libraries/api";

import { toast, ToastContainer } from "react-toastify";

toast.configure({
  autoClose: 2000,
  draggable: false,
});
function updateProduct(productId, statusProduct) {
  var productUpdateConfigUrl = "/products-config/id/" + productId;
  var parameters = { status: statusProduct };
  Api.put(productUpdateConfigUrl, parameters, false).then((resp) => {});
}

class Modules extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
    this.updateReleaseManagement = this.updateReleaseManagement.bind(this);
    this.saveParkingModuleOptions = this.saveParkingModuleOptions.bind(this);
  }

  saveModuleOptions(e) {
    e.preventDefault();
    const productList = document.getElementsByName("productStatus");
    for (var i = 0; i < productList.length; i++) {
      updateProduct(productList[i].value, productList[i].checked);
    }
    toast.success("Successfully updated!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }

  updateReleaseManagement(status, reason) {
    const relManagementAddUrl = "/release-management-add";
    var parameters = { toggleValue: status, reason };
    return Api.post(relManagementAddUrl, parameters, true, "parking");
  }

  saveParkingModuleOptions(e) {
    e.preventDefault();
    const records = new Map();
    const productList = document.getElementsByName("ParkingProductStatus");
    for (let i=0; i < productList.length; i++) {
      records.set(productList[i].id, { toggleValue: productList[i].checked ? 1 : 0, reason: "" })
    }
    // only the ones that show
    const reasonList = document.getElementsByName("ParkingProductReason");
    for (let i=0; i < reasonList.length; i++) {
      const key = reasonList[i].id;
      const currentObj = records.get(key);
      const newObj = { ...currentObj, 
        reason: currentObj.toggleValue === 1 ? "" : reasonList[i].value 
      } // reason for disabling (0)
      // set the new value
      records.set(key, newObj)
    }
    // add more promises here
    const promises = [];
    records.forEach((v,k) => {
      promises.push(this.updateReleaseManagement(v.toggleValue, v.reason))
    })
    // wait on all promises here
    Promise.all(promises)
    .then((resp) => {
      toast.success("Successfully updated!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    })
    .catch((err) => {
      console.error(err)
    })
    .finally(() => {
    });
  }

  render() {
    return (
      <div>
        <h5 className='text-title-big' style={{marginBottom:'20px'}}>Modules</h5>
        <div className="card col-6">
          <div className="card-header">
            <h5 className="card-title">Parking</h5>
          </div>
          <br />
          <div>
            <form
              id="frmParkingProduct"
              name="frmParkingProduct"
              className="form-horizontal"
              onSubmit={this.saveParkingModuleOptions}
              method="Post"
            >
              <ParkingProductList />
              <div class="row">
                <div class="cols-md-1">
                  <input
                    type="submit"
                    value="Update"
                    class="btn btn-blue"
                    style={{ margin: "10px" }}
                  />
                </div>
              </div>
            </form>
          </div>  
        </div>        
        <br />
        <div className="card col-6">
          <p>Select the modules to be displayed in the mobile app.</p>
          <p>
            <strong>Basic account:</strong>
          </p>
          <div>
            <form
              id="frmProductConfig"
              name="frmProductConfig"
              className="form-horizontal"
              onSubmit={this.saveModuleOptions}
              method="Post"
            >
              <ProductConfigList />
              <div class="row">
                <div class="cols-md-1">
                  <input
                    type="submit"
                    value="Update"
                    class="btn btn-blue"
                    style={{ margin: "10px" }}
                  />
                </div>
              </div>
            </form>
          </div>  
        </div>
        <ToastContainer />
      </div>
    );
  }
}
export default Modules;
