import React, { Component } from "react";
import "./switch.css";
export default class ParkingProductCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked || false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange() {
    this.setState({ isChecked: !this.state.isChecked }, () => {
        this.props.handleChange(this.props.product, this.state.isChecked)
    });
  }
  render() {
    return (
      <div>
        <label className="switch">
          <input
            type="checkbox"
            name="ParkingProductStatus"
            id={this.props.product}
            checked={this.state.isChecked}
            defaultChecked={this.props.isChecked}
            onChange={this.handleChange}
          />
          <div className="slider"></div>
        </label>
      </div>
    );
  }
}