import Storage from "./storage";
import Moment from "moment";
import AuthHelper from "./auth-helper";
import { API_ONBOARDING, API_URL, API_PARKING } from "./constans";

require('dotenv').config();

class Api {

    static headers(route, params, verb,host) {

        let headers = {

            'Accept': 'application/json',

            'Content-Type': 'application/json',

            'dataType': 'json',

        };

        let expression = "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]\\.[^\\s]{2,})";

        let regex = new RegExp(expression);

        if (!route.match(regex)) {

            let access_token = Storage.get('access_token');

            if (access_token) {

                access_token = access_token.replace(/['"]+/g, '');

                headers = {

                    'Accept': 'application/json',

                    'Content-Type': 'application/json',

                    'Authorization': `Bearer ${access_token}`,

                    'dataType': 'json',

                };

                return this.xhr(route, params, headers, verb, regex,host);

            }

        }

        return this.xhr(route, params, headers, verb, regex,host);

    }

    static refreshToken(host) {

        let route = '/refreshToken';

        let expression = "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]\\.[^\\s]{2,})";

        let regex = new RegExp(expression);

        let refresh_token = Storage.get('refresh_token');

        refresh_token = refresh_token.replace(/['"]+/g, '');

        let headers = {

            'Accept': 'application/json',

            'Content-Type': 'application/json',

            'Authorization': `Bearer ${refresh_token}`,

            'dataType': 'json',

        };

        return this.xhr(route, null, headers, 'GET', regex,host);


    }

    static get(route, check = true, host='core') {

        if (check) {

            this.checkRefreshToken(host);

        }

        return this.headers(route, null, 'GET',host);

    }

    static put(route, params, check = true, host='core') {

        if (check) {

            this.checkRefreshToken();

        }

        return this.headers(route, params, 'PUT',host)

    }

    static post(route, params, check = true, host='core') {

        if (check) {

            this.checkRefreshToken();

        }

        return this.headers(route, params, 'POST',host)

    }

    static delete(route, params, check = true, host='core') {

        if (check) {

            this.checkRefreshToken();

        }

        return this.headers(route, params, 'DELETE',host)

    }

    static xhr(route, params, headers, verb, regex,hostURL) {

        let arrStatusCode = [401];

        let url = null;

        let options = Object.assign({method: verb}, params ? {body: JSON.stringify(params)} : null);

        if (!route.match(regex)) {
            let host;
            // const host = hostURL === 'onboarding' ? API_ONBOARDING :  API_URL
            if (hostURL === 'onboarding'){
                host = API_ONBOARDING
            }
            else if(hostURL === 'parking'){
                host = API_PARKING
            }
            else{
                host = API_URL
            }
            url = `${host}${route}`;
        } else {
            url = route;
        }

        options.headers = headers;

        return fetch(url, options).then(resp => {
            let json = resp.json();

            json = Promise.all([resp.status, json]).then(res => ({
                ...res[1],
                statusCode: res[0],
            }));

            if (resp.ok) {

                return json;

            }

            return json.then(errors => {

                throw errors

            });

        })

            .then(json => json)

            .catch(errors => {

                if(errors.errors !== undefined){
                    if (errors.errors.messages === 'User not found') {
                        AuthHelper.logOut();
                    }
                }

                if (arrStatusCode.includes(errors.statusCode) &&
                    route !== '/login' &&
                    !route.match(regex)) {

                    switch (errors.statusCode) {
                        case 401: {
                            alert('Invalid Token. You will be redirected to Login page.');
                            break;
                        }
                        default: {
                            break;
                        }
                    }

                    AuthHelper.logOut();

                } else {

                    throw errors;

                }

            });

    }

    static isTokenExpired() {

        let expired_at = Moment.unix(Storage.get('expired_at'));

        let temp_now = Moment().format();

        return Math.sign(expired_at.diff(temp_now)) === -1;

    };

    static checkRefreshToken() {

        if (this.isTokenExpired()) {

            this.refreshToken().then(resp => {

                AuthHelper.setLogin(resp.data);

            }).catch(err => {

                if (err.errors.messages === 'Token has expired') {
                    AuthHelper.logOut();
                }

                console.log(err);

            });
        }

    };

}

export default Api;
