import React, {Component} from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import UploadFile from "./upload-file";
import Api from "../../../libraries/api";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import UploadsHistoryTable from "./upload-history";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import {toast} from "react-toastify";
import UploadDetail from "./upload-detail";

function a11yProps(index) {

    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

export default class Uploads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            showWsDotTrans: true,
            fileBase64: '',
            loading: true,
            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            perPage: 50,
            currentPage: 0,
            currentPageTable: 0,
            totalData: 0,
            search: '',
            uploadDetail: [],
            showDetail: false,
            loadingDetail: false,
            file: Date.now(),
            transactionType:this.props.match.params.type
        };
        this.getHistoryUpload();
        this.handleFileInputChange = this.handleFileInputChange.bind(this);
        this.handleSearchUpload = this.handleSearchUpload.bind(this);
        this.saveUpload = this.saveUpload.bind(this);
        this.handleCloseDetail = this.handleCloseDetail.bind(this);
    }

    handleTabChange(e, newValue) {
        let showWsDotTrans = false;
        if (newValue === 0) {
            showWsDotTrans = true;
        }
        this.setState({
            activeTab: newValue,
            showWsDotTrans: showWsDotTrans,
        });
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage,
            currentPageTable: newPage,
            loading: true,
        }, () => {
            this.getHistoryUpload();
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: event.target.value,
            currentPage: 0,
            currentPageTable: 0,
            loading: true,
        }, () => {
            this.getHistoryUpload();
        });
    };

    saveUpload() {
        if (this.state.fileBase64 === "") {
            this.setState({errors: "The file selected is not valid."})
            return false
        }
        this.setState({
            loading: true,
            file: Date.now(),
        })
        let params = {
            file: (this.state.transactionType==='wsdot')?(this.state.fileBase64):(this.state.fileBase64.replace("77u\/", '')),
        };
        let urlAccounts = (this.state.transactionType==='wsdot')?("/uploads/ws-dot/transaction"):("/uploads/tapnpay/transaction")
        Api.post(urlAccounts, params, false).then(resp => {
            this.setState({
                loading: true,
                errors: "",
                fileBase64: '',
            })
            this.getHistoryUpload()
            toast.success("Successfully!", {
                position: toast.POSITION.TOP_CENTER,
            });
        }).catch(err => {
            this.setState({
                loading: false,
                fileBase64: '',
                errors: err.errors.messages
            })
        });
    }

    handleFileInputChange(e) {
        let {file} = this.state;
        file = e.target.files[0];
        this.getBase64(file)
            .then(result => {
                file["base64"] = result;
                this.setState({
                    fileBase64: result,
                    errors: ""
                });
            }).catch(err => {
            console.log(err);
        });
    };

    getBase64 = file => {
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    handleCloseDetail() {
        this.setState({
            loading: false,
            showDetail: false,
        })
    }

    getHistoryUpload() {
        let currentPage = this.state.currentPage + 1;
        let historyUploadUrl = "/uploads?perPage=" + this.state.perPage + "&pageNumber=" + currentPage;
        Api.get(historyUploadUrl, [], false).then((resp) => {
            this.setState({
                rows: resp["data"]["uploads"],
                loading: false,
                totalData: resp["data"]["meta"]["totalData"]
            });
        }).catch(err=>{
            this.setState({loading: false});
        });
    }

    handleSearchUpload(id) {
        this.setState({
            loadingDetail: true,
        }, () => {
            let uploadIdUrl = "/uploads/id/" + id
            Api.get(uploadIdUrl, [], false).then((resp) => {
                this.setState({
                    uploadDetail: resp["data"],
                    showDetail: true,
                    loadingDetail: false
                });
            });
        });

    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <AppBar position="static" color="default">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2>Upload Csv</h2>
                            </div>
                        </div>
                        <Tabs
                            value={this.state.activeTab}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label={this.state.transactionType=="wsdot"?"WsDot Transactions":"TapNpay Transactions"} {...a11yProps(0)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={this.state.activeTab} index={0}>
                        {(this.state.showWsDotTrans === true) ? (<UploadFile loading={this.state.loading}
                                                                             saveUpload={this.saveUpload}
                                                                             file={this.state.file}
                                                                             handleFileInputChange={this.handleFileInputChange}/>) : ('')}
                        <div>
                            {(this.state.errors) ?
                                (
                                    <div className="col-12" style={{"color": "red"}}>
                                        <span>Error</span><br/>
                                        {this.state.errors}
                                    </div>
                                ) : ('')
                            }
                        </div>
                    </TabPanel>
                    <UploadDetail showDetail={this.state.showDetail}
                                  handleCloseDetail={this.handleCloseDetail}
                                  uploadDetail={this.state.uploadDetail}/>
                    <UploadsHistoryTable loading={this.state.loading}
                                         rows={this.state.rows}
                                         totalData={this.state.totalData}
                                         perPage={this.state.perPage}
                                         currentPageTable={this.state.currentPageTable}
                                         handleChangePage={this.handleChangePage}
                                         handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                         ActionsComponent={TablePaginationActions}
                                         handleSearchUpload={this.handleSearchUpload}
                    />
                </div>
            </div>
        )
    }
}