import { CircularProgress, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
import React, { Component } from 'react'
import Api from '../../../libraries/api'
//import DateFormat from '../../../libraries/data-format-helper'
import { history } from '../../../shared/configure-store'
import TablePaginationActions from '../../presentational/table-pagination-actions'
import moment from "moment";

export default class BookingsLog extends Component {

  constructor(props){
    super(props)
    this.state={
      rows:[],
      loading:true,
      total: 0,
      perPage: 50,
      currentPage: 0,
      currentPageTable: 0,
      totalData: 0,
    }
    this.getBookingsLogList()
  }

  getBookingsLogList(update){
    let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;
    Api.get("/bookings-get-all?limit="+this.state.perPage+'&page='+page, true, "parking").then(resp=>{
      //console.log("resp",resp)
      this.setState({
        loading:false,
        rows:resp.data.rows,
        total: resp.data.total_rows,
        perPage: this.state.perPage,
        currentPage: resp.data.page,
        currentPageTable: resp.data.page - 1,
        totalData: resp.data.total_rows,
      })
    }).catch(err=>{
      this.setState({loading:false})
      console.log("error",err)
    })
  }

  handleChangePage = (event, newPage) => {
    this.setState({
        rows: [],
        currentPage: newPage,
        currentPageTable: newPage,
        loading: true,
    }, () => {
        this.getBookingsLogList(true);
    });
};

handleChangeRowsPerPage = event => {
    this.setState({
        perPage: event.target.value,
        currentPage: 0,
        currentPageTable: 0,
        loading: true,
    }, () => {
        this.getBookingsLogList(false);
    });
};


  render() {
    return (
      <div >
        <h2 className='text-title-big'>Bookings

        </h2>
        <div className='card' style={{marginTop:'20px'}}>
        <div className="table-wrapper">
          <Table className="table-list mt-3" size="small">
              <TableHead>
                  <TableRow>
                    <TableCell>User Id</TableCell>
                    <TableCell>Booking Id</TableCell>
                    <TableCell>Final Price</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell>Canceled On</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                {this.state.loading ? (
                    <TableRow>
                        <TableCell colSpan={8} align="center" className="py-5">
                            <CircularProgress color="primary"/>
                        </TableCell>
                    </TableRow>
                ) : (
                    this.state.rows.length === 0 ? (
                        <TableRow style={{height: 53 * 10}}>
                            <TableCell colSpan={8} style={{textAlign: "center"}}>No transaction</TableCell>
                        </TableRow>
                    ) : (
                        this.state.rows.map((row, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    style={{cursor:'pointer'}}
                                    onClick={() => history.push('/accounts/detail/' + row.userId)}
                                    selected={row.selected}
                                >
                                  <TableCell>{row.userId}</TableCell>
                                  <TableCell>{row.bookingId}</TableCell>
                                  <TableCell>{parseFloat(row.finalPrice).toFixed(2)}</TableCell>
                                  <TableCell>{row.startTime === null ? "NA" : moment(row.startTime).format("MM/DD/YYYY, hh:mm:ss A")}</TableCell>
                                  <TableCell>{row.endTime === null ? "NA" : moment(row.endTime).format("MM/DD/YYYY, hh:mm:ss A")}</TableCell>
                                  <TableCell>{row.cancelledAt === null ? "NA" : moment(row.cancelledAt).format("MM/DD/YYYY, hh:mm:ss A")}</TableCell>
                                </TableRow>
                            )
                        })
                    )
                )}
              </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[50, 75, 100]}
          component="div"
          count={this.state.totalData}
          rowsPerPage={this.state.perPage}
          page={this.state.currentPageTable}
          backIconButtonProps={{
              'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
              'aria-label': 'next page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        </div>
      </div>
    )
  }
}
