import React, { Component } from 'react';
import Chart from "chart.js";
import Api from "../../../libraries/api";
import { CircularProgress } from "@material-ui/core";

class ChartSuccesFulLicensePlateRegistrationV2 extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            period: 'daily',
            loading: false,
            totalData: [],
            pendingData: [],
            pendingTotalUsersPercentage: [],
            pendingIphoneUsers: [],
            pendingIphoneUsersPercentage: [],
            pendingAndroidUsers: [],
            pendingAndroidUsersPercentage: [],
            pendingDeviceNotCapturedUsers: [],
            pendingDeviceNotCapturedUsersPercentage: [],
            successfulData: [],
            successfulDataTotalUsersPercentage: [],
            successfulIphoneUsers: [],
            successfulIphoneUsersPercentage: [],
            successfulAndroidUsers: [],
            successfulAndroidUsersPercentage: [],
        };
    }

    componentDidMount() {
        this.getDeviceChart(this.state.period);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.period !== this.state.period) {
            this.getDeviceChart(this.state.period);
        }
    }

    getDeviceChart = (period) => {
        this.setState({ loading: true });
        Api.get('/reports/user-successful-registration-v2?period=' + period, true).then(resp => {
            if (resp.data && Array.isArray(resp.data.data)) {
                const labels = resp.data.columns; 
                const data = resp.data.data; 
                const totalData = data.map(item => item.totalData);
                const pendingData = data.map(item => item.pendingData);
                const pendingTotalUsersPercentage = data.map(item => item.pendingTotalUsersPercentage);
                const pendingIphoneUsers = data.map(item => item.pendingIphoneUsers);
                const pendingIphoneUsersPercentage = data.map(item => item.pendingIphoneUsersPercentage);
                const pendingAndroidUsers = data.map(item => item.pendingAndroidUsers);
                const pendingAndroidUsersPercentage = data.map(item => item.pendingAndroidUsersPercentage);
                const pendingDeviceNotCapturedUsers = data.map(item => item.pendingDeviceNotCapturedUsers);
                const pendingDeviceNotCapturedUsersPercentage = data.map(item => item.pendingDeviceNotCapturedUsersPercentage);
                const successfulData = data.map(item => item.successfulData);
                const successfulDataTotalUsersPercentage = data.map(item => item.successfulDataTotalUsersPercentage);
                const successfulIphoneUsers = data.map(item => item.successfulIphoneUsers);
                const successfulIphoneUsersPercentage = data.map(item => item.successfulIphoneUsersPercentage);
                const successfulAndroidUsers = data.map(item => item.successfulAndroidUsers);
                const successfulAndroidUsersPercentage = data.map(item => item.successfulAndroidUsersPercentage);
    
                this.setState({
                    loading: false,
                    labels,
                    totalData,
                    pendingData,
                    pendingTotalUsersPercentage,
                    pendingIphoneUsers,
                    pendingIphoneUsersPercentage,
                    pendingAndroidUsers,
                    pendingAndroidUsersPercentage,
                    pendingDeviceNotCapturedUsers,
                    pendingDeviceNotCapturedUsersPercentage,
                    successfulData,
                    successfulDataTotalUsersPercentage,
                    successfulIphoneUsers,
                    successfulIphoneUsersPercentage,
                    successfulAndroidUsers,
                    successfulAndroidUsersPercentage
                }, () => {
                    this.__chart(
                        this.state.labels,
                        this.state.totalData,
                        this.state.pendingData,
                        this.state.pendingTotalUsersPercentage,
                        this.state.pendingIphoneUsers,
                        this.state.pendingIphoneUsersPercentage,
                        this.state.pendingAndroidUsers,
                        this.state.pendingAndroidUsersPercentage,
                        this.state.pendingDeviceNotCapturedUsers,
                        this.state.pendingDeviceNotCapturedUsersPercentage,
                        this.state.successfulData,
                        this.state.successfulDataTotalUsersPercentage,
                        this.state.successfulIphoneUsers,
                        this.state.successfulIphoneUsersPercentage,
                        this.state.successfulAndroidUsers,
                        this.state.successfulAndroidUsersPercentage
                    );
                });
            } else {
                console.error('Unexpected API data structure:', resp.data);
                this.setState({ loading: false });
            }
        }).catch(err => {
            console.error('API call failed:', err);
            this.setState({ loading: false });
        });
    }
    __chart = (labels, totalData, pendingData, pendingTotalUsersPercentage, pendingIphoneUsers, pendingIphoneUsersPercentage, pendingAndroidUsers, pendingAndroidUsersPercentage, pendingDeviceNotCapturedUsers, pendingDeviceNotCapturedUsersPercentage, successfulData, successfulDataTotalUsersPercentage, successfulIphoneUsers, successfulIphoneUsersPercentage, successfulAndroidUsers, successfulAndroidUsersPercentage) => {
        if (this.chartRef.current) {
            const maxValue = Math.max(...pendingData, ...successfulData);
            const yMax = Math.ceil(maxValue / 10) * 10;
            var barChartData = {
                labels: labels,
                datasets: [
                    {
                        label: 'iOS - Pending',
                        backgroundColor: '#008299',
                        data: pendingIphoneUsers,
                        stack: 'Pending'
                    },
                    {
                        label: 'Android - Pending',
                        backgroundColor: '#21732d',
                        data: pendingAndroidUsers,
                        stack: 'Pending'
                    },
                    {
                        label: 'Device Not Captured - Pending',
                        backgroundColor: '#A9A9A9',
                        data: pendingDeviceNotCapturedUsers,
                        stack: 'Pending'
                    },
                    {
                        label: 'iOS - Successful',
                        backgroundColor: '#10d7ee',
                        data: successfulIphoneUsers,
                        stack: 'Successful'
                    },
                    {
                        label: 'Android - Successful',
                        backgroundColor: '#7ee810',
                        data: successfulAndroidUsers,
                        stack: 'Successful'
                    }
                ]
            };
    
            let myChartRef = this.chartRef.current.getContext("2d");
            let myChart = new Chart(myChartRef, {
                type: 'bar',
                data: barChartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            stacked: true,
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                                max: yMax  // Set the dynamic max for the y-axis
                            }
                        }]
                    },
                    legend: {
                        position: 'top',
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'nearest', 
                        intersect: true,
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let value = tooltipItem.value;
                                let pd = pendingData[tooltipItem.index];
                                let successful = successfulData[tooltipItem.index];
                                let pendingPercentage = pendingTotalUsersPercentage[tooltipItem.index];
                                let successfulPercentage = successfulDataTotalUsersPercentage[tooltipItem.index];
                                if(dataset.label === 'iOS - Pending'){
                                    let percentage = pendingIphoneUsersPercentage[tooltipItem.index];
                                    let ios = pendingIphoneUsers[tooltipItem.index];
                                    return `iOS: ${ios} (${percentage}%) totalPending: ${pd} (${pendingPercentage}%)`
                                }
                                else if(dataset.label === 'Android - Pending'){
                                    let p = pendingAndroidUsersPercentage[tooltipItem.index];
                                    let android = pendingAndroidUsers[tooltipItem.index];
                                    return `Android: ${android} (${p}%) totalPending: ${pd} (${pendingPercentage}%)`
                                }
                                else if(dataset.label === 'Device Not Captured - Pending'){
                                    let percentage = pendingDeviceNotCapturedUsersPercentage[tooltipItem.index];
                                    let dnc = pendingDeviceNotCapturedUsers[tooltipItem.index];
                                    return `Not Captured: ${dnc} (${percentage}%) totalPending: ${pd} (${pendingPercentage}%)`
                                }
                                else if(dataset.label === 'iOS - Successful'){
                                    let percentage = successfulIphoneUsersPercentage[tooltipItem.index];
                                    let ios = successfulIphoneUsers[tooltipItem.index];
                                    return `iOS: ${ios} (${percentage}%) totalSuccessful: ${successful} (${successfulPercentage}%)`
                                }
                                else {
                                    //Android(Successful)
                                    let percentage = successfulAndroidUsersPercentage[tooltipItem.index];
                                    let android = successfulAndroidUsers[tooltipItem.index];
                                    return `Android: ${android} (${percentage}%) totalSuccessful: ${successful} (${successfulPercentage}%)`
                                }
                            }
                        }
                    }
                }
            });
            myChart.update();
        } else {
            console.error("Chart ref is not available.");
        }
    }    
    
    handleChange = (target) => {
        this.setState({ period: target });
    };

    render() {
        return (
            <div>
                <div className="col-12" style={{ marginTop: '20px', padding: '0px' }}>
                    <div className="col-12 card">
                        <div className="row title-dashboard">
                            <div className="col-6">
                                <h2>License Plate Entries</h2>
                            </div>
                        </div>
                        <div className="d-md-flex justify-content-center mt-4 mb-4">
                            <nav className="nav nav-filter">
                                <button className={"nav-link pl-0 " + (this.state.period === 'daily' && 'active')}
                                        onClick={() => this.handleChange('daily')}>Daily
                                </button>
                                <button className={"nav-link " + (this.state.period === 'weekly' && 'active')}
                                        onClick={() => this.handleChange('weekly')}>Weekly
                                </button>
                                <button className={"nav-link " + (this.state.period === 'monthly' && 'active')}
                                        onClick={() => this.handleChange('monthly')}>Monthly
                                </button>
                            </nav>
                        </div>
                        <ShowDeviceChart loading={this.state.loading} chartRef={this.chartRef}/>
                        <div id="chartjs-tooltip" style={{ opacity: 0, position: 'absolute', backgroundColor: '#fff', border: '1px solid #ddd', padding: '10px', pointerEvents: 'none', transition: 'all 0.3s ease' }}></div>
                    </div>
                </div>
            </div>
        )
    }
}

class ShowDeviceChart extends Component {
    render() {
        if (this.props.loading) {
            return <CircularProgress />;
        } else {
            return (
                <div className="chart-container">
                    <canvas id="myChart" ref={this.props.chartRef} />
                </div>
            );
        }
    }
}

export default ChartSuccesFulLicensePlateRegistrationV2;