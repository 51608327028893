import React, { Component } from 'react';
import Chart from "chart.js";
import Api from "../../../libraries/api";
import { CircularProgress } from "@material-ui/core";

class ChartRevenue extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            period: 'daily',
            loading: false
        };
    }

    componentDidMount() {
        this.getDeviceChart(this.state.period);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.period !== this.state.period) {
            this.getDeviceChart(this.state.period);
        }
    }

    getDeviceChart = (period) => {
        this.setState({ loading: true });
        //
        Api.get('/reports/register-users-v2?period=' + period, true).then(resp => {
            if (resp.data) {
                let labels = resp.data["registerUsers"]["columns"];
                let revenue = resp.data["registerUsers"]["revenue"];
                this.setState({ loading: false }, () => {
                    this.__chart(labels, revenue);
                });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ loading: false });
        });
    }

    __chart = (labels, revenue) => {
        if (this.chartRef.current) {
            var barChartData = {
                labels: labels,
                datasets: [
                {
                    label: 'Revenue Collected',
                    backgroundColor: '#10d7ee',
                    borderColor: '#10d7ee',
                    borderWidth: 1,
                    datalabels: {
                        color: 'transparent'
                    },
                    data: revenue
                }]
            };
            let myChartRef = this.chartRef.current.getContext("2d");
            new Chart(myChartRef, {
                type: 'bar',
                data: barChartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0
                            }
                        }]
                    },
                    legend: {
                        position: 'top',
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                                if (label) {
                                    label += ': ';
                                }
                                label += '$' + tooltipItem.yLabel.toFixed(2);
                                return label;
                            }
                        }
                    },
                }
            }).update();
        } else {
            console.error("Chart ref is not available.");
        }
    }

    handleChange = (target) => {
        this.setState({ period: target });
    };

    render() {
        return (
            <div>
                <div className="col-12" style={{ marginTop: '20px', padding: '0px' }}>
                    <div className="col-12 card">
                        <div className="row title-dashboard">
                            <div className="col-6">
                                <h2>Revenue Generated</h2>
                            </div>
                        </div>
                        <div className="d-md-flex justify-content-center mt-4 mb-4">
                            <nav className="nav nav-filter">
                                <button className={"nav-link pl-0 " + (this.state.period === 'daily' && 'active')}
                                        onClick={() => this.handleChange('daily')}>Daily
                                </button>
                                <button className={"nav-link " + (this.state.period === 'weekly' && 'active')}
                                        onClick={() => this.handleChange('weekly')}>Weekly
                                </button>
                                <button className={"nav-link " + (this.state.period === 'monthly' && 'active')}
                                        onClick={() => this.handleChange('monthly')}>Monthly
                                </button>
                            </nav>
                        </div>
                        <ShowDeviceChart loading={this.state.loading} chartRef={this.chartRef}/>
                    </div>
                </div>
            </div>
        )
    }
}

class ShowDeviceChart extends Component {
    render() {
        if (this.props.loading) {
            return <CircularProgress />;
        } else {
            return (
                <div className="chart-container">
                    <canvas id="myChart" ref={this.props.chartRef} />
                </div>
            );
        }
    }
}

export default ChartRevenue;