import React, {Component} from 'react';
import AuthHelper from "../../../libraries/auth-helper";
import ChartTickets from "./chart-tickets";
import ChartFulfillment from "./chart-fulfillment";
import DashboardTotal from "./dashboard-total";
import ChartTotalLogin from "./chart-total-loggin";
import ChartRevenue from "./chart-revenue";
import ChartSuccesFulLicensePlateRegistrationV2 from "./chart-user-successful-licensePlateRegV2";
import ChartNewUserAccountsDevice from "./chart-new-user-accounts-device";
import ChartTollTransaction from "./chart-toll-transaction";
import ChartPaymentStatusDeclined from "./chart-payment-status-declined";

class ContentDashboard extends Component {

    constructor(props) {
        super(props);
        let profile = AuthHelper.getProfile()
        this.state = {
            role: profile.role["name"]
        }
    }

    render() {
        if (this.state.role === 'Super Admin' || this.state.role === 'Management') {
            return (
                <div>
                    <div className="row">
                        <div className="col-12">
                            <DashboardTotal/>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartTotalLogin/>
                                </div>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartNewUserAccountsDevice/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartRevenue/>
                                </div>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartSuccesFulLicensePlateRegistrationV2/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartTollTransaction/>
                                </div>
                                <div className='col-md-6 col-sm-12'>
                                    <ChartPaymentStatusDeclined/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.role === 'Sales') {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-10 col-lg-9">
                            <ChartFulfillment/>
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.role === 'Customer Support Rep') {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-10 col-lg-9">
                            <ChartTickets/>
                        </div>
                    </div>
                </div>
            )
        }
        return false
    }
}

export default ContentDashboard;